/* import project1foto1 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/1.jpeg';
import project1foto2 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/2.jpeg';
import project1foto3 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/3.jpeg';
import project1foto4 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/4.jpeg';
import project1foto5 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/5.jpeg';
import project1foto6 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/6.jpeg';
import project1foto7 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/7.jpeg';
import project1foto8 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/8.jpeg';
import project1foto9 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/9.jpeg';
import project1foto10 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/10.jpeg';
import project1foto11 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/11.jpeg';
import project1foto12 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/12.jpeg';
import project1foto13 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/13.jpeg';
import project1foto14 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/14.jpeg';
import project1foto15 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/15.jpeg';
import project1foto16 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/16.jpeg';
import project1foto17 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/17.jpeg';
import project1foto18 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/18.jpeg';
import project1foto19 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/19.jpeg';
import project1foto20 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/20.jpeg';
import project1foto21 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/21.jpeg';
import project1foto22 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/22.jpeg';
import project1foto23 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/23.jpeg';
import project1foto24 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/24.jpeg';
import project1foto25 from '../assets/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/25.jpeg';

import project2foto1 from '../assets/editorial/calendario_2023_cuerpo_de_agua/1.jpeg';
import project2foto2 from '../assets/editorial/calendario_2023_cuerpo_de_agua/2.jpeg';
import project2foto3 from '../assets/editorial/calendario_2023_cuerpo_de_agua/3.jpeg';
import project2foto4 from '../assets/editorial/calendario_2023_cuerpo_de_agua/4.jpeg';

import project3foto1 from '../assets/editorial/maria_sabina/1.jpeg'; */


export const projectsPhotos2 = {
    0 : ["https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/1.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/2.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/3.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/4.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/5.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/6.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/7.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/8.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/9.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/10.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/11.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/12.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/13.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/14.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/15.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/16.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/17.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/18.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/19.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/20.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/21.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/22.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/23.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/24.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/25.webp"],
    1 : ["https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/calendario_2023_cuerpo_de_agua/1.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/calendario_2023_cuerpo_de_agua/2.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/calendario_2023_cuerpo_de_agua/3.webp", 
        "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/calendario_2023_cuerpo_de_agua/4.webp"],
    2 : ["https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/maria_sabina/1.webp"]
}
export const projectsPhotos2PC = {
    0 : ["https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/pc1.webp", "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/libro_de_poesia_infantil_canturia_arrullos_y_poemas/pc2.webp"],
    1 : ["https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/calendario_2023_cuerpo_de_agua/pc1.webp", "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/calendario_2023_cuerpo_de_agua/pc2.webp"],
    2 : ["https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/maria_sabina/pc1.webp", "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/maria_sabina/pc2.webp", "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/maria_sabina/pc3.webp", "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/maria_sabina/pc4.webp", "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/maria_sabina/pc5.webp", "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/maria_sabina/pc6.webp", "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/maria_sabina/pc7.webp", "https://raw.githubusercontent.com/ErickMagallan/GaleriaLuciaSarabia/main/editorial/maria_sabina/pc8.webp"]
}